import invariant from "invariant";
import { observable, action, computed, runInAction } from "mobx";
import RootStore from "~/stores/RootStore";
import { Invoice, Subscription, PaymentMethod } from "~/types";
import { client } from "~/utils/ApiClient";

type State = "fetching" | "subscribing" | "canceling";

type Plan = {
  id: string;
  name: string;
  price: number;
  priceMonthly: number;
  maxUsers: number;
};

class BillingStore {
  @observable data: Subscription | undefined;
  @observable invoices: Invoice[] = [];
  @observable paymentMethods: PaymentMethod[] = [];
  @observable state: State | undefined;
  rootStore: RootStore;

  plans: Plan[] = [
    {
      id: "starter",
      name: "Starter",
      price: 10000,
      priceMonthly: 1000,
      maxUsers: 10,
    },
    {
      id: "team",
      name: "Team",
      price: 75000,
      priceMonthly: 7900,
      maxUsers: 100,
    },
    {
      id: "business",
      name: "Business",
      price: 225000,
      priceMonthly: 24900,
      maxUsers: 250,
    },
  ];

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @computed
  get isLoaded(): boolean {
    return !!this.data;
  }

  @computed
  get isInTrial(): boolean {
    return (
      !!this.data?.trialEndsAt &&
      this.data.status !== "active" &&
      new Date(this.data.trialEndsAt) > new Date()
    );
  }

  @computed
  get isCanceled(): boolean {
    return this.data?.status === "canceled" && !this.isInTrial;
  }

  @computed
  get isActive(): boolean {
    return this.data?.status === "active";
  }

  @computed
  get isPastDue(): boolean {
    return this.data?.status === "past_due";
  }

  @computed
  get isInactive(): boolean {
    return this.data?.status === "inactive" && !this.isInTrial;
  }

  @computed
  get interval(): "month" | "year" | void {
    return this.data?.plan?.interval;
  }

  @computed
  get plan(): Plan {
    const userCount = this.data ? this.data.userCount : 0;

    return (
      this.plans.find((plan) => userCount <= plan.maxUsers) || this.plans[2]
    );
  }

  @computed
  get price() {
    const defaultAmount =
      this.interval === "month" ? this.plan.priceMonthly : this.plan.price;
    return this.data?.plan?.amount || defaultAmount;
  }

  fetchLicenseStatus = async () => {
    const res = await client.post("/subscription.licenseStatus");
    invariant(res && res.data, "Data should be available");
    return res.data;
  };

  @action
  fetchSubscription = async () => {
    const res = await client.post("/subscription.status");
    invariant(res && res.data, "Data should be available");
    const { data } = res;

    runInAction("fetchSubscription", () => {
      this.data = data;
    });
  };

  @action
  fetchInvoices = async (
    options: Record<string, any> | undefined
  ): Promise<Invoice[]> => {
    const res = await client.post("/subscription.invoices", options);
    invariant(res && res.data, "Data should be available");
    const { data } = res;

    runInAction("fetchInvoices", () => {
      this.invoices = data;
    });

    return this.invoices;
  };

  @action
  fetchPaymentMethods = async () => {
    const res = await client.post("/subscription.paymentMethods");
    invariant(res && res.data, "Data should be available");
    const { data } = res;

    runInAction("paymentMethods", () => {
      this.paymentMethods = data;
    });
  };

  @action
  cancelSubscription = async (options: { reason: string }) => {
    this.state = "canceling";

    const res = await client.post("/subscription.cancel", options);
    invariant(res && res.data, "Data should be available");
    const { data } = res;

    runInAction("cancelSubscription", () => {
      this.data = data;
    });

    this.state = undefined;
    this.rootStore.auth.fetch();
  };

  @action
  updateSubscription = async (options: { email: string }) => {
    this.state = "subscribing";

    const res = await client.post("/subscription.update", options);
    invariant(res && res.data, "Data should be available");
    const { data } = res;

    runInAction("updateSubscription", () => {
      this.data = data;
    });

    this.state = undefined;
    this.rootStore.auth.fetch();
  };
}

export default BillingStore;
